export interface ResultsItem {
  link: string
}

export interface ComponentItem {
  name: string
  icon: string
  _id: string
  results: ResultsItem[]
}

export const componentsList: ComponentItem[] = [
  {
    _id: 'tables',
    name: 'Tables',
    icon: 'table_chart',
    results: []
  },
  {
    _id: 'hook',
    name: 'Hooks',
    icon: 'input',
    results: []
  },
  {
    _id: 'task',
    name: 'Tasks',
    icon: 'assignment',
    results: []
  },
  {
    _id: 'forms',
    name: 'Forms',
    icon: 'assignment',
    results: []
  },
  {
    _id: 'vueBlocks',
    name: 'Vue Block',
    icon: 'assignment',
    results: []
  },
  {
    _id: 'hookPDF',
    name: 'Hooks PDF',
    icon: 'input',
    results: []
  },
  {
    _id: 'collections',
    name: 'Collections',
    icon: 'collections_bookmark',
    results: []
  },
  {
    _id: 'blocks',
    name: 'Blocks',
    icon: 'table_chart',
    results: []
  },
  {
    _id: 'views',
    name: 'Views',
    icon: 'table_chart',
    results: []
  },
  {
    _id: 'endpoint',
    name: 'Endpoints V1',
    icon: 'comment',
    results: []
  },
  {
    _id: 'apiEndpoints',
    name: 'Endpoints V2',
    icon: 'comment',
    results: []
  },
  {
    _id: 'graphScript',
    name: 'Graph Scripts',
    icon: 'endpoint',
    results: []
  }
]
