
























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'

import { ComponentItem, componentsList } from './componentItems'

@Component({
  components: {
    Loading
  }
})
export default class ComponentBrowser extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string

  components: ComponentItem[] = []
  searchInput: String = ''
  loading: Boolean = false

  async search() {
    if (!this.searchInput.length) return
    this.loading = true

    try {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation componentBrowser($environmentId: String, $search: String) {
            componentBrowser(environmentId: $environmentId, search: $search) {
              results
            }
          }
        `,
        variables: {
          environmentId: this.environmentId,
          search: this.searchInput
        }
      })
      this.components = componentsList.map((c) => {
        return {
          ...c,
          results: res.data.componentBrowser.results[c._id]?.length
            ? res.data.componentBrowser.results[c._id]
            : undefined
        }
      })
    } catch (error) {
      console.error(error)
      this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error al obtener los componentes',
        color: 'error'
      })
    }
    this.loading = false
  }
}
